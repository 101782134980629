.container {
  min-height: 50vh;
  @apply grid place-content-center text-center;
}

.heading {
  @apply text-3-125xl text-green py-4;
}

.subheading {
  @apply py-4;
}

.button {
  @apply my-12 text-white bg-orange rounded-lg px-8 py-2 mx-auto text-lg font-medium;

  &:hover {
    @apply bg-green duration-150;
  }

  &:focus {
    @apply outline-none;
  }
}
