.container {
  @apply z-10
  self-center;
  display: flex;
  margin-top: 15px;
  align-items: center;
}

.logo {
  @apply cursor-pointer
  block;
}
/* .hamburger {
  margin-top: 15px;
} */
